<template>
  <section class="doc9">
    <h1
      style="font-size: 23px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件5
    </h1>
    <h1
      style="font-size: 23px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      衛生福利部補助偏鄉護理菁英公費生
    </h1>
    <h2
      style="font-size: 23px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      收 支 明 細 表
    </h2>
    <h3>受補助單位: {{ school }}</h3>
    <h3>補助年度:{{ year }}學年度第{{ term }}學期</h3>
    <h3>計畫名稱: 偏鄉護理菁英計畫</h3>
    <header class="grid-4-header">
      <div class="left">
        <div></div>
      </div>
      <div class="right">
        <div style="padding:4px;">
          <h6>第一次核撥日期</h6>
          <h6>{{ when }}</h6>
          <h6>金額</h6>
          <h6>$ {{ amount }} 元</h6>
        </div>
        <div style="padding:4px;">
          <h6>第二次核撥日期</h6>
          <h6>
            <pre>   年    月     日</pre>
          </h6>
          <h6>金額</h6>
          <h6>
            <pre>$               元</pre>
          </h6>
        </div>
        <div class="border-r-1"></div>
        <div></div>
        <div style="padding:4px;">
          <h6>第一次餘(絀)數</h6>
          <h6>金額</h6>
          <h6>
            <pre>$               元</pre>
          </h6>
        </div>
        <div class="border-r-1" style="padding:4px;">
          <h6>第二次餘(絀)數</h6>
          <h6>金額</h6>
          <h6>
            <pre>$               元</pre>
          </h6>
        </div>
        <div class="border-b-0" style="padding:4px;">
          <h6>第一次結報日期</h6>
          <h6>{{ when2 }}</h6>
          <h6>金額</h6>
          <h6>$ {{ amount2 }} 元</h6>
        </div>
        <div class="border-b-0" style="padding:4px;">
          <h6>第二次結報日期</h6>
          <h6>
            <pre>   年    月     日</pre>
          </h6>
          <h6>金額</h6>
          <h6>
            <pre>$               元</pre>
          </h6>
        </div>
        <div class="border-r-1 border-b-0"></div>
      </div>
    </header>
    <main v-for="(item, i) in rows" :key="i" class="grid-4-dtc">
      <div>{{ item }}</div>
      <div v-if="i == 0" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.Fee_1) }}
      </div>
      <div v-if="i == 1" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.Fee_2) }}
      </div>
      <div v-if="i == 2" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.Fee_3) }}
      </div>
      <div v-if="i == 3" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.Fee_4) }}
      </div>
      <div v-if="i == 4" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.Fee_5) }}
      </div>
      <div v-if="i == 5" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.Fee_6) }}
      </div>
      <div v-if="i == 6" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.SubTotal) }}
      </div>
      <div v-if="i == 7" style="text-align: right;padding-right:8px">
        ${{ $formatPrice(map.Surplus) }}
      </div>
      <div></div>
      <div></div>
    </main>
    <main class="grid-2-dtc">
      <div style="line-height:120px;">備註</div>
      <div class="extra-note" style="border-right: 1px solid black;">
        <h6>1.繳回款: 經常門 ${{ $formatPrice(map.Often) }} 元</h6>
        <h6>資本門 ${{ $formatPrice(map.Capital) }} 元</h6>
        <h6>
          2.其他衍生收入: $ {{ $formatPrice(map.Other_Income) }} 元,
          請於結報時併同結餘款解繳本部。
        </h6>
      </div>
    </main>

    <footer class="mt-2">
      <div>製表人</div>
      <div>覆核</div>
      <div>會計人員</div>
      <div class="f-last" data-msg="簽約代表人">校長</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
const rowObj = {};
const rows = [
  "補助公費生待遇",
  "設備費",
  "業務費",
  "行政管理費",
  "",
  "",
  "小計",
  "餘 (絀) 數",
];
//年月日
export default {
  name: "totalSpend9",
  data() {
    return {
      rows,
      map: {},
      school: "",
      term: "",
      year: "",
      when: "",
      amount: "",
      amount2: "",
    };
  },
  async mounted() {
    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //check Can see and then get data

    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = 1,
      category: Category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = term; // == 1 ? '上' : '下';
    this.year = Byear;
    let schoolArr = await window.axios.get("School/GetAll");
    this.school = schoolArr.find((s) => s.SchoolCode == SchoolCode).SchoolName;
    try {
      this.map = await axios.get(
        `BalanseSheet/Get?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
      );
      let ret = this.map.Approve_Date.split("T")[0];
      let arr = ret.split("-");
      let mY = +arr[0] - 1911;
      this.when = `${mY} 年 ${arr[1]} 月 ${arr[2]} 日`;
      ret = this.map.Checkout_Date.split("T")[0];
      arr = ret.split("-");
      mY = +arr[0] - 1911;
      this.when2 = `${mY} 年 ${arr[1]} 月 ${arr[2]} 日`;

      this.amount = this.$formatPrice(this.map.Approve_Fee);
      this.amount2 = this.$formatPrice(this.map.Checkout_Fee);
    } catch (e) {
      alert(e);
    }
  },
};
</script>

<style lang="scss">
.doc9 {
  margin: 0 auto;
  background: white;
  width: 760px;
}
.grid-4-header {
  display: grid;
  background: white;
  grid-template-columns: 160px 600px;
  border: 1px solid black;
  .left,
  .right {
    position: relative;
    background: white;
    > div {
      border: 1px solid black;
    }
  }
  .left::before,
  .left::after {
    position: absolute;
    font-weight: normal !important;
    top: 5px;
    right: 30px;
    font-size: 16px;
    color: black;
    content: "核撥 (結報)";
    writing-mode: vertical-lr;
    font-size: 16px;
    height: 200px;
  }
  .left::after {
    content: "經費預算核撥數";
    top: 220px;
    right: 120px;
  }
  .left {
    display: grid;
    place-items: center;
    > div {
      height: 100%;
      width: 1px;
      box-shadow: 0 10px 0 0 black, 0 -10px 0 0 black;
      transform: rotate(-23deg); //translateX(90px);
    }
  }
  .right {
    display: grid;
    grid-template-columns: repeat(3, 200px);
    > div {
      height: 120px;
      border-top: none;
      border-right: none;
    }
  }
}
h1,
h2 {
  text-align: center;
  font-size: 24px;
}
h2 {
  margin: 1rem 0;
  font-size: 22px;
}
h3 {
  text-align: left;
  margin: 10px 0;
  font-size: 16px;
}
.border-r-1 {
  border-right: 1px solid black !important;
}
.border-b-0 {
  border-bottom: none !important;
}
.grid-4-dtc {
  display: grid;
  grid-template-columns: 161px repeat(3, 200px);
  height: 40px;
  border: 1px solid black;
  border-right: none;
  border-top: none;
  line-height: 40px;
  > div {
    padding-left: 4px;
    border-right: 1px solid black;
  }
}
.grid-2-dtc {
  display: grid;
  grid-template-columns: 161px 600px;
  height: 120px;
  border: 1px solid black;
  border-right: none;
  border-top: none;
  > div {
    padding-left: 4px;
    border-right: 1px solid black;
  }
}
.extra-note {
  padding: 10px;
  > h6:nth-child(2) {
    margin-left: 70px;
    margin-bottom: 25px;
  }
}

footer {
  margin-top: 0rem;
  display: grid;
  padding-left: 10px;
  grid-template-columns: repeat(4, 1fr);
  height: 100px;
}
.f-last {
  position: relative;
  &::after {
    position: absolute;
    top: 18%;
    left: 0;
    width: 120px;
    height: 20px;
    content: "(簽約代表人)";
  }
}
@media print {
  @page {
    size: A4 portrait;
  }
  pre {
    border: 0px solid #adb5bd;
  }
}
</style>
